<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Um Überarbeitung bitten">
      <el-form
        ref="rejectionForm"
        :model="rejection"
        :rules="rules"
        label-position="top"
      >
        <el-col :sm="24" :md="16" :lg="8" :xl="8">
          <el-form-item label="Begründung" prop="reason">
            <el-input
              v-model="rejection.reason"
              type="textarea"
              :rows="3"
            ></el-input>
          </el-form-item>

          <el-checkbox v-model="rejection.notifySelf">
            E-Mail Benachrichtigung auch an mich senden
          </el-checkbox>
        </el-col>
      </el-form>
    </page-content>
    <portal to="fixed-footer-left">
      <el-button type="primary" :loading="saving" @click="checkForm">
        Anfrage abschicken
      </el-button>
      <el-button @click="$router.go(-1)">
        Abbrechen
      </el-button>
    </portal>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import EventsRepository from "@/repositories/events_repository.js"
import FixedFooterMixin from "@/mixins/fixed_footer"
import MessagesMixin from "@/mixins/messages"
import FormMixin from "@/mixins/form.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  metaInfo: {
    title: "Um Überarbeitung bitten"
  },
  components: {
    TheBreadcrumbs,
    PageContent
  },
  mixins: [FixedFooterMixin, MessagesMixin, FormMixin, ApiErrorHandlerMixin],
  data() {
    return {
      saving: false,
      rejection: {
        reason: null,
        notifySelf: false
      }
    }
  },
  computed: {
    rules() {
      return {
        reason: [
          { required: true, message: "Bitte Grund angeben", trigger: "blur" }
        ]
      }
    },
    datesWithoutDeleted() {
      return this.event.dates_attributes.filter(date => date._destroy !== true)
    }
  },
  async created() {},
  methods: {
    checkForm() {
      this.$refs["rejectionForm"].validate(valid => {
        if (valid) {
          this.submitForm()
        } else {
          this.showFormErrors()
        }
      })
    },
    async submitForm() {
      this.saving = true

      try {
        await EventsRepository.askForEdit(
          this.$route.params.id,
          this.rejection.reason,
          this.rejection.notifySelf
        )
        this.saving = false
        this.showCustomMessage(
          "Anfrage wurde an Veranstalter geschickt.",
          "success"
        )

        try {
          await this.$router.push({
            name: "EventsPage",
            query: { allow_redirect: true }
          })
        } catch {
          //
        }
      } catch (error) {
        this.saving = false
        this.handleApiError(error)
      }
    }
  }
}
</script>
